.inputFile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.fileUploadSection{
	height: 100%;
	width: 100%;
}
.fileUploadSection .file-drop-target{
	height: 100%;
}
