.react-tel-input {
    width: 450px;
    border: 1px solid  #ccc;
    border-radius: 5px;
}

.react-tel-input .flag-dropdown {
    height: 45px;
    border: 0;
    border-radius: 8px;
    background-color: #EBF7FB;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    height: 45px;
    border: 0;
    border-radius: 8px;
    background-color: #EBF7FB;
}

.react-tel-input .form-control {
    height: 45px;
    border: 0;
    border-radius: 8px;
    background-color: #EBF7FB;
    width: 100%;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: #EBF7FB;
}

.react-tel-input .country-list .divider {
    display: none;
}