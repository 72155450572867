.info-header {
    background: #2FAED7;
    padding: 1rem;
}

.box-wrapper {
    margin-top: 1rem;
}

.series-text {
    color: #0099CC;
    font-weight: 500;
    font-size: 18px;
}